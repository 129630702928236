import { useState } from 'react';

const useStepManager = ({
  startFrom = 1,
  onStep,
}) => {
  const [stepNumber, setStepNumber] = useState(startFrom);

  const setParamsWrapper = (callback) => (params, step) => {
    onStep(params);
    callback(step);
  };

  const setNextStep = setParamsWrapper((step) => {
    setStepNumber(step || stepNumber + 1);
  });

  const setPreviousStep = setParamsWrapper((step) => {
    setStepNumber(step || (stepNumber - 1 < 1 ? 1 : stepNumber - 1));
  });

  return { stepNumber, setNextStep, setPreviousStep };
};

export default useStepManager;
