import axios from 'axios';
import get from 'lodash/get';
import axiosRetry from 'axios-retry';

import triggerLoadingAction from 'helpers/loader';
import getAccessToken from 'helpers/getAccessToken';
import { CANCEL_REQUESTS } from 'data/types/event.types';

export default (refreshTokenEvent) => ({ getState }) => (next) => async (action) => {
  if (!action) return false;
  const state = getState();
  const endpoint = get(action, 'payload.endpoint');
  const method = get(action, 'payload.method');
  const isCustom = get(action, 'payload.custom', false);
  const body = get(action, 'payload.body');
  const params = get(action, 'payload.params', {});
  const onSuccess = get(action, 'onSuccess', () => {});
  const onFailed = get(action, 'onFailed', () => {});
  const components = get(action, 'components', null);
  const skipAuth = get(action, 'payload.skipAuth', false);
  const delayLoad = get(action, 'payload.delayLoad', 1000);
  const responseType = get(action, 'payload.responseType');
  const showResponseError = get(action, 'payload.showResponseError', false);
  const ignore401 = get(action, 'payload.ignore401', false);
  const cancelToken = get(action, 'payload.cancelToken');
  const cancelEvent = get(action, 'payload.cancelEvent');
  const retries = get(action, 'payload.retries');
  // use it if you want to test some api endpoint which might be heavy
  const delayRequest = get(action, 'debug.delayRequest');
  const isDemoUser = get(state, 'user.isDemoUser');

  const apiHost = process.env.REACT_APP_API_URL;

  if (isCustom) {
    return next(action);
  }
  if (get(action, 'data')) return next(action);
  if (get(action, 'payload.event')) return next(action);

  let loading = true;
  setTimeout(() => {
    if (loading) {
      triggerLoadingAction(components, true, next);
    }
  }, delayLoad);

  try {
    const accessToken = skipAuth || await getAccessToken(refreshTokenEvent);

    if (!accessToken) {
      return next({
        type: 'SIGN_OUT',
        data: {},
      });
    }

    const client = axios.create({ baseURL: apiHost });
    if (retries) {
      axiosRetry(client, { retries, retryDelay: axiosRetry.exponentialDelay });
    }

    const res = await client({
      url: endpoint,
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: body,
      params,
      responseType,
      cancelToken,
    });

    if (cancelEvent) window.removeEventListener(CANCEL_REQUESTS, cancelEvent);

    const data = get(res, 'data');
    if (onSuccess) onSuccess(data);

    loading = false;
    setTimeout(() => triggerLoadingAction(components, false, next), 0);

    if (process.env.NODE_ENV === 'development' && delayRequest) {
      setTimeout(() => {
        next({
          ...action,
          payload: data,
        });
      }, delayRequest);
      return false;
    }
    return next({
      ...action,
      payload: data,
    });
  } catch (err) {
    loading = false;
    setTimeout(() => triggerLoadingAction(components, false, next), 0);
    if (!axios.isCancel(err)) {
      if (get(err, 'response.status', '') === 401 && !ignore401) {
        localStorage.removeItem('accessToken');
        return next({
          type: 'SIGN_OUT',
          data: {},
        });
      }
      if (isDemoUser && (err.response?.status === 429 || err.response?.status >= 500)) {
        next({
          type: 'MOVE_DEMO_TO_PROD',
          data: {
            code: err.response?.status,
          },
        });
      } else if (showResponseError) {
        if (typeof showResponseError !== 'function' || showResponseError(err)) {
          let header = get(err, 'response.data.message.header', get(err, 'response.data.header'));
          let text;

          if (err.response?.status === 400) {
            text = get(err, 'response.data.errors[0].details', '');
          }

          if (!text) {
            if (header) text = get(err, 'response.data.message.text', get(err, 'response.data.text'));
            else text = get(err, 'response.data.message', get(err, 'response.data'));
          }

          if ((!text && !header) || typeof text === 'object') {
            header = 'Something went wrong';
            text = '';
          }
          header = text || header;
          text = '';

          next({
            type: 'TOGGLE_MESSAGE',
            data: {
              type: 'error',
              options: {
                header,
                text,
              },
            },
          });
        }
      }
      if (onFailed) return onFailed(err);
    }
    return false;
  }
};
