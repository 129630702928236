export const SOURCE_TYPE = Object.freeze({
  COMPANY: 'company',
  CONTACT: 'contact',
  LIST: 'list',
});

export const ENRICH_TYPE = Object.freeze({
  COMPANY: SOURCE_TYPE.COMPANY,
  CONTACT: SOURCE_TYPE.CONTACT,
  LINKEDIN: 'linkedin',
  EMAIL: 'email',
});
