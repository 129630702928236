import { GP_GROUP } from 'data/types/user.types';
import { getCookie, setCookie } from 'hooks/useSignUpQuery';

export const updateGpGroup = (groupNumber, isRandom = true) => {
  let gpGroup = getCookie('gp_group');
  if (!gpGroup) {
    const condition = isRandom ? (new Date().getUTCSeconds() % 2) === 0 : true;
    gpGroup = condition ? GP_GROUP.DEFAULT : groupNumber;
    const threeMonths = 7776000;
    const options = {
      domain: process.env.REACT_APP_WEBSITE_URL,
      'max-age': threeMonths,
    };
    setCookie('gp_group', gpGroup, options);
  }
  return +gpGroup;
};
