import { Tooltip } from 'antd';
import React, { useState } from 'react';

import {
  IconExistingList,
  IconIgnoreList,
  IconImportCompanies,
  IconImportContacts,
  IconImportUpload,
  IconImportData,
  IconTwoWaySync,
  IconOnboardingFindLead,
  IconOnboardingInfo,
  IconOnboardingEnrich,
  IconOnboardingOrganize,
  IconEnrichment,
  IconEmptyState,
  IconChrome,
  IconDocuments,
  IconGoogle,
  IconFormatClear,
  IconFormatListBulleted,
  IconFormatItalic,
  IconFormatUnderlined,
  IconFormatColorText,
  IconFormatColorFill,
  IconFormatAlignRight,
  IconFormatAlignJustify,
  IconFormatAlignCenter,
  IconFormatAlignLeft,
  IconCode,
  IconDownload,
  MaterialIcon,
  IconInfoOutline,
  IconDataDotObject,
  IconArrowVectorRight,
  IconLinkedIn,
  IconYoutube,
  IconInstagram,
  IconTwitter,
  IconFacebook,
} from './style';

const Icon = ({
  type = '',
  fill = 'default',
  fillOnHover,
  style,
  className,
  onClick,
  size = 'default',
  tooltipOptions,
  outlined = false,
  disabled,
  typeOnHover,
  ...rest
}) => {
  const [hover, setHover] = useState(false);

  const icon = (Prop) => (
    <Prop onClick={onClick} className={className} fill={fill} style={style} />
  );

  const renderMaterialIcon = (text) => {
    // eslint-disable-next-line
    let { title, ...restTooltipOptions } = (tooltipOptions || {});
    if (tooltipOptions && !title) {
      title = 'Open link';
    }

    return (
      // eslint-disable-next-line
      <Tooltip title={title} {...restTooltipOptions}>
        <MaterialIcon
          // eslint-disable-next-line
          {...rest}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          disabled={disabled}
          fillOnHover={fillOnHover}
          outlined={outlined}
          size={size}
          onClick={onClick}
          className={className}
          fill={fill}
          style={style}
          translate="no"
        >
          {text}
        </MaterialIcon>
      </Tooltip>
    );
  };

  const text = hover && typeOnHover ? typeOnHover : type;

  switch (text) {
    case 'ic-help':
      return renderMaterialIcon('help');
    case 'ic-facebook':
      return icon(IconFacebook);
    case 'ic-youtube':
      return icon(IconYoutube);
    case 'ic-twitter':
      return icon(IconTwitter);
    case 'ic-instagram':
      return icon(IconInstagram);
    case 'ic-public':
      return renderMaterialIcon('public');
    case 'ic-sticky-note-2':
      return renderMaterialIcon('sticky_note_2');
    case 'ic-linkedin':
      return icon(IconLinkedIn);
    case 'ic-thumb-down':
      return renderMaterialIcon('thumb_down');
    case 'ic-list-alt':
      return renderMaterialIcon('list_alt');
    case 'ic-text-outline':
      return renderMaterialIcon('text_outline');
    case 'ic-access-time':
      return renderMaterialIcon('access_time');
    case 'ic-not-started':
      return renderMaterialIcon('not_started');
    case 'ic-timelapse':
      return renderMaterialIcon('timelapse');
    case 'ic-forward-to-inbox':
      return renderMaterialIcon('forward_to_inbox');
    case 'ic-ondemand-video':
      return renderMaterialIcon('ondemand_video');
    case 'ic-tune':
      return renderMaterialIcon('tune');
    case 'ic-contacts':
      return renderMaterialIcon('contacts');
    case 'ic-unsubscribe':
      return renderMaterialIcon('unsubscribe');
    case 'ic-keyboard-arrow-left':
      return renderMaterialIcon('keyboard_arrow_left');
    case 'ic-keyboard-arrow-right':
      return renderMaterialIcon('keyboard_arrow_right');
    case 'ic-keyboard-arrow-down':
      return renderMaterialIcon('keyboard_arrow_down');
    case 'ic-keyboard-arrow-up':
      return renderMaterialIcon('keyboard_arrow_up');
    case 'ic-article':
      return renderMaterialIcon('article');
    case 'ic-data-object':
      return renderMaterialIcon('data_object');
    case 'ic-drag-handle':
      return renderMaterialIcon('drag_handle');
    case 'ic-calendar-today':
      return renderMaterialIcon('calendar_today');
    case 'ic-download':
      return icon(IconDownload);
    case 'ic-do-not-disturb-alt':
      return renderMaterialIcon('do_not_disturb_alt');
    case 'ic-trending-up':
      return renderMaterialIcon('trending_up');
    case 'ic-trending-down':
      return renderMaterialIcon('trending_down');
    case 'ic-schedule':
      return renderMaterialIcon('schedule');
    case 'ic-source':
      return renderMaterialIcon('source');
    case 'ic-data-dot-object':
      return icon(IconDataDotObject);
    case 'ic-code':
      return icon(IconCode);
    case 'ic-cancel-schedule-send':
      return renderMaterialIcon('cancel_schedule_send');
    case 'ic-pause-circle-outline':
      return renderMaterialIcon('pause_circle_outline');
    case 'ic-format-align-left':
      return icon(IconFormatAlignLeft);
    case 'ic-format-align-center':
      return icon(IconFormatAlignCenter);
    case 'ic-format-align-justify':
      return icon(IconFormatAlignJustify);
    case 'ic-format-align-right':
      return icon(IconFormatAlignRight);
    case 'ic-format-color-fill':
      return icon(IconFormatColorFill);
    case 'ic-format-color-text':
      return icon(IconFormatColorText);
    case 'ic-format-underlined':
      return icon(IconFormatUnderlined);
    case 'ic-format-italic':
      return icon(IconFormatItalic);
    case 'ic-format-bold':
      return renderMaterialIcon('format_bold');
    case 'ic-format-list-bulleted':
      return icon(IconFormatListBulleted);
    case 'ic-format-clear':
      return icon(IconFormatClear);
    case 'ic-pause':
      return renderMaterialIcon('pause');
    case 'ic-warning':
      return renderMaterialIcon('warning');
    case 'ic-pause-circle':
      return renderMaterialIcon('pause_circle');
    case 'ic-file-download':
      return renderMaterialIcon('file_download');
    case 'ic-bar-chart':
      return renderMaterialIcon('bar_chart');
    case 'ic-reply':
      return renderMaterialIcon('reply');
    case 'ic-mouse':
      return renderMaterialIcon('mouse');
    case 'ic-send':
      return renderMaterialIcon('send');
    case 'ic-tabs':
      return renderMaterialIcon('subject');
    case 'ic-visibility':
      return renderMaterialIcon('visibility');
    case 'ic-visibility-off':
      return renderMaterialIcon('visibility_off');
    case 'ic-group-add':
      return renderMaterialIcon('group_add');
    case 'ic-people':
      return renderMaterialIcon('group');
    case 'ic-domain':
      return renderMaterialIcon('domain');
    case 'ic-documents':
      return icon(IconDocuments);
    case 'ic-chrome':
      return icon(IconChrome);
    case 'ic-arrow-drop-up':
      return renderMaterialIcon('arrow_drop_up');
    case 'ic-arrow-drop-down':
      return renderMaterialIcon('arrow_drop_down');
    case 'ic-email':
      return renderMaterialIcon('email');
    case 'ic-phone':
      return renderMaterialIcon('phone');
    case 'ic-add':
      return renderMaterialIcon('add');
    case 'ic-expand-less':
      return renderMaterialIcon('expand_less');
    case 'ic-expand-more':
      return renderMaterialIcon('expand_more');
    case 'ic-edit':
      return renderMaterialIcon('edit');
    case 'ic-chevron-left':
      return renderMaterialIcon('chevron_left');
    case 'ic-menu':
      return renderMaterialIcon('menu');
    case 'ic-close':
      return renderMaterialIcon('close');
    case 'ic-delete':
      return renderMaterialIcon('delete');
    case 'ic-open-in-new': {
      return renderMaterialIcon('launch');
    }
    case 'ic-existing-list':
      return icon(IconExistingList);
    case 'ic-ignore-list':
      return icon(IconIgnoreList);
    case 'ic-import-companies':
      return icon(IconImportCompanies);
    case 'ic-import-contacts':
      return icon(IconImportContacts);
    case 'ic-import-upload':
      return icon(IconImportUpload);
    case 'ic-upload-file':
      return renderMaterialIcon('upload_file');
    case 'ic-attach-file':
      return renderMaterialIcon('attach_file');
    case 'ic-check-circle':
      return renderMaterialIcon('check_circle');
    case 'ic-check':
      return renderMaterialIcon('check');
    case 'ic-cancel':
      return renderMaterialIcon('cancel');
    case 'ic-import-data':
      return icon(IconImportData);
    case 'ic-work':
      return renderMaterialIcon('work');
    case 'ic-touch-app':
      return renderMaterialIcon('touch_app');
    case 'ic-two-way-sync':
      return icon(IconTwoWaySync);
    case 'ic-drag-indicator':
      return renderMaterialIcon('drag_indicator');
    case 'ic-onboarding-find-lead':
      return icon(IconOnboardingFindLead);
    case 'ic-onboarding-info':
      return icon(IconOnboardingInfo);
    case 'ic-onboarding-enrich':
      return icon(IconOnboardingEnrich);
    case 'ic-onboarding-organize':
      return icon(IconOnboardingOrganize);
    case 'ic-chevron-right':
      return renderMaterialIcon('chevron_right');
    case 'ic-enrichment':
      return icon(IconEnrichment);
    case 'ic-person':
      return renderMaterialIcon('person');
    case 'ic-info':
      return renderMaterialIcon('info');
    case 'ic-logout':
      return renderMaterialIcon('logout');
    case 'ic-arrow-back':
      return renderMaterialIcon('arrow_back');
    case 'ic-arrow-forward':
      return renderMaterialIcon('arrow_forward');
    case 'ic-folder':
      return renderMaterialIcon('folder');
    case 'ic-home':
      return renderMaterialIcon('home');
    case 'ic-empty-state':
      return icon(IconEmptyState);
    case 'ic-google':
      return icon(IconGoogle);
    case 'ic-search':
      return renderMaterialIcon('search');
    case 'ic-save':
      return renderMaterialIcon('save');
    case 'ic-outgoing-mail':
      return renderMaterialIcon('outgoing_mail');
    case 'ic-remove':
      return renderMaterialIcon('remove');
    case 'ic-description':
      return renderMaterialIcon('description');
    case 'ic-cloud-download':
      return renderMaterialIcon('cloud_download');
    case 'ic-event-note':
      return renderMaterialIcon('event_note');
    case 'ic-list':
      return renderMaterialIcon('list');
    case 'ic-more-vert':
      return renderMaterialIcon('more_vert');
    case 'ic-notifications':
      return renderMaterialIcon('notifications');
    case 'ic-settings':
      return renderMaterialIcon('settings');
    case 'ic-radio-button-checked':
      return renderMaterialIcon('radio_button_checked');
    case 'ic-radio-button-unchecked':
      return renderMaterialIcon('radio_button_unchecked');
    case 'ic-filter-alt':
      return renderMaterialIcon('filter_alt');
    case 'ic-play-circle':
      return renderMaterialIcon('play_circle');
    case 'ic-credit-card':
      return renderMaterialIcon('credit_card');
    case 'ic-more-horiz':
      return renderMaterialIcon('more_horiz');
    case 'ic-person-search':
      return renderMaterialIcon('person_search');
    case 'ic-person-add-alt-1':
      return renderMaterialIcon('person_add_alt_1');
    case 'ic-mark-email-read':
      return renderMaterialIcon('mark_email_read');
    case 'ic-published-with-changes':
      return renderMaterialIcon('published_with_changes');
    case 'ic-content-copy':
      return renderMaterialIcon('content_copy');
    case 'ic-restore':
      return renderMaterialIcon('restore');
    case 'ic-signal-cellular-alt':
      return renderMaterialIcon('signal_cellular_alt');
    case 'ic-refresh':
      return renderMaterialIcon('refresh');
    case 'ic-star':
      return renderMaterialIcon('star');
    case 'ic-store':
      return renderMaterialIcon('store');
    case 'ic-add-business':
      return renderMaterialIcon('add_business');
    case 'ic-block':
      return renderMaterialIcon('block');
    case 'ic-history':
      return renderMaterialIcon('history');
    case 'ic-bookmark-bordered':
      return renderMaterialIcon('bookmark_border');
    case 'ic-bookmark':
      return renderMaterialIcon('bookmark');
    case 'ic-filter':
      return renderMaterialIcon('filter_alt');
    case 'ic-error':
      return renderMaterialIcon('error');
    case 'ic-arrow-down':
      return renderMaterialIcon('keyboard_arrow_down');
    case 'ic-lock':
      return renderMaterialIcon('lock');
    case 'ic-info-outline':
      return icon(IconInfoOutline);
    case 'ic-lock-open':
      return renderMaterialIcon('lock_open');
    case 'ic-assignment-return':
      return renderMaterialIcon('assignment_return');
    case 'ic-monetization-on':
      return renderMaterialIcon('monetization_on');
    case 'ic-report':
      return renderMaterialIcon('report');
    case 'ic-receipt':
      return renderMaterialIcon('receipt');
    case 'ic-arrow-vector-right':
      return icon(IconArrowVectorRight);
    default:
      return '';
  }
};

export default Icon;
