import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  signUp,
  checkEmail,
  updateSignUpReport,
  updateSignUpAdditionalData,
} from 'data/actions/user';
import {
  validateEmail,
  passwordLengthValidate,
  passwordHasSpecialSymbol,
  validateName,
} from 'helpers/validate';

import useTinyForm from 'hooks/useTinyForm';
import { pluginInstalledSelector } from 'data/selectors/user';
import { Input, Button } from 'components/common';
import GoogleAuthBtn from 'components/googleAuth/googleAuthBtn';
import { signUpLoadingSelector } from 'data/selectors/loading';
import { GP_GROUP } from 'data/types/user.types';
import { updateGpGroup } from 'helpers/pricing/dynamicPricing';
import {
  useSetSignUpQuery,
  getQueryParams,
  getCookieDataByDestination,
  getQueryData,
  getCookie,
} from 'hooks/useSignUpQuery';
import AuthContainer from '../AuthContainer';
import {
  AuthForm,
  Header,
  linkStyle,
  FormWrapper,
  InputWrapper,
  SubHeader,
} from '../styles';
import {
  FirstNameWrapper,
  FormDividerSignUp,
  HeaderWrapper,
  InputInOneLineContainer,
  BtnGetStartedStyle,
} from './styles';

const SignUpPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  useSetSignUpQuery();
  const loadingSignUp = useSelector(signUpLoadingSelector);

  const pluginInstalled = useSelector(pluginInstalledSelector);

  const [numberStep, setNumberStep] = useState(1);
  const [captchaToken, setCaptchaToken] = useState(null);

  const queryData = getQueryData();
  const queryParams = getQueryParams();
  const getQueryParamByName = (name) => queryParams.get(name) || null;
  const queryRelationEmail = queryData?.email || queryData?.relationEmail || getQueryParamByName('relationEmail');
  const queryRelationToken = queryData?.relationToken || getQueryParamByName('relationToken');
  const onFailed = (_data, callback) => {
    const status = _data?.response?.status;
    const messageError = _data?.response?.data?.message;
    if (status === 409) return callback('User already exists');
    if (messageError) return callback(messageError);
    return callback('Something went wrong');
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;

    const recTokenResult = await executeRecaptcha('signUp');
    setCaptchaToken(recTokenResult);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    const utmMedium = get(queryData, 'utmMedium');
    const additionalData = {};

    if (utmMedium === 'demo' || getCookie('completedDemoTour')) {
      additionalData.demoSearchLeadsDone = true;
      dispatch(updateSignUpAdditionalData(additionalData));
    }
  }, [dispatch]); // eslint-disable-line

  const handleSignUp = (data) => {
    const affiliate = parseInt(getQueryParamByName('queryAffiliate'), 10);
    data.gp_group = updateGpGroup(GP_GROUP.NEW_PRICING_3);
    dispatch(signUp({
      ...data,
      relationToken: queryRelationToken,
      pluginInstalled: typeof pluginInstalled === 'boolean' ? pluginInstalled : false,
      captchaToken,
      affiliate,
      utmCampaign: getCookieDataByDestination('utmCampaign'),
      utmTerm: getCookieDataByDestination('utmTerm'),
      utmSource: get(queryData, 'utmSource', get(queryData, 'utm_source')) ?? getCookieDataByDestination('utmSource'),
      utmMedium: get(queryData, 'utmMedium', get(queryData, 'utm_medium')) ?? getCookieDataByDestination('utmMedium'),
      utmPage: getCookie('utmPage'),
    }));
    setNumberStep(4);
    dispatch(updateSignUpReport({ email: data.email, password: true }));
  };

  const onSubmit = ({ data, setErrors }) => {
    if (numberStep === 1) {
      dispatch(checkEmail({
        email: data.email,
        onSuccess: () => {
          setNumberStep(numberStep + 1);
          localStorage.removeItem('tempEmail');
        },
        onFailed: (error) => onFailed(error, (messageError) => setErrors('email', messageError)),
      }));
    } else if (numberStep === 3) {
      handleSignUp(data);
    } else {
      setNumberStep(numberStep + 1);
    }
  };

  const {
    data,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    isDisabledForm,
  } = useTinyForm({
    schema: {
      email: {
        value: queryRelationEmail || '',
        conditions: [
          {
            label: 'Invalid email',
            condition: validateEmail,
          },
        ],
      },
      firstName: {
        value: '',
        conditions: [
          {
            label: 'Must contain value',
            condition: validateName,
          },
        ],
      },
      lastName: {
        value: '',
        conditions: [
          {
            label: 'Must contain value',
            condition: validateName,
          },
        ],
      },
      password: {
        value: '',
        conditions: [
          {
            label: 'Must contain min 12 symbols and max 128 symbols',
            condition: passwordLengthValidate,
          },
          {
            label: 'At least one special character needed',
            condition: passwordHasSpecialSymbol,
          },
        ],
      },
    },
    stepData: {
      step: numberStep,
      propertyKeys: [
        ['email'],
        ['firstName', 'lastName'],
        ['phone'],
        ['password'],
      ],
    },
    onSubmit,
  });

  const handleCheckPassword = useCallback(() => {
    if (!passwordLengthValidate(data?.password)) {
      setErrors('password', 'Must contain min 12 symbols and max 128 symbols');
      return false;
    }
    if (!passwordHasSpecialSymbol(data?.password)) {
      setErrors('password', 'At least one special character needed');
      return false;
    }
    setErrors('password', '');
    return true;
  }, [data.password, setErrors]);

  useEffect(() => {
    if (data?.password?.length > 3) handleCheckPassword();
  }, [data.password, handleCheckPassword]);

  const isDisabledButtonGetStarted = isDisabledForm
    || !captchaToken
    || data?.password?.length < 12
    || ![3, 4, 5].includes(numberStep);

  const handleCheckEmail = () => {
    if (validateEmail(data.email)) {
      dispatch(checkEmail({
        email: data.email,
        onSuccess: () => {
          setErrors('email');
          dispatch(updateSignUpReport({ email: data.email }));
        },
        onFailed: (error) => onFailed(error, (messageError) => setErrors('email', messageError)),
      }));
    }
  };

  useEffect(() => {
    if (numberStep === 1 && data.email.includes('@')) {
      const hostEmail = data.email.split('@')[1];
      if (hostEmail && hostEmail.includes('.')) {
        const splittedEmail = hostEmail.split('.');
        if (splittedEmail[splittedEmail.length - 1]?.length > 1) {
          setNumberStep(2);
        }
      }
    }
  }, [data.email, numberStep]);

  const handleCheckFirstName = useCallback(() => {
    if (data.firstName?.trim()?.length < 1) {
      setErrors('firstName', 'This field can\'t be empty');
      return false;
    }
    if (data.firstName?.trim()?.length === 1) {
      setErrors('firstName', 'Must be 2 or more characters long');
      return false;
    }
    if (!/^([a-zA-Z]|[U+0060]|[']|[-]|[.]|\s)+$/.test(data.firstName?.trim())) {
      setErrors('firstName', 'Only latin letters (A-z) are allowed');
      return false;
    }
    setErrors('firstName', '');
    return true;
  }, [data.firstName, setErrors]);

  const handleCheckLastName = useCallback(() => {
    if (data.lastName?.trim()?.length < 1) {
      setErrors('lastName', 'This field can\'t be empty');
      return false;
    }
    if (data.lastName?.trim()?.length === 1) {
      setErrors('lastName', 'Must be 2 or more characters long');
      return false;
    }
    if (!/^([a-zA-Z]|[U+0060]|[']|[-]|[.]|\s)+$/.test(data.lastName?.trim())) {
      setErrors('lastName', 'Only latin letters (A-z) are allowed');
      return false;
    }
    setErrors('lastName', '');
    return true;
  }, [data.lastName, setErrors]);

  const handleCheckUsername = useCallback(() => {
    if (!errors?.firstName && !errors.lastName && data.firstName && data.lastName) return true;
    return false;
  }, [errors, data.lastName, data.firstName]);

  useEffect(() => {
    if (numberStep === 2 && handleCheckUsername()) {
      dispatch(updateSignUpReport({ email: data.email, name: true }));
      setNumberStep(3);
    }
  }, [numberStep, data.email, dispatch, errors, handleCheckUsername]);

  return (
    <AuthContainer content="signUp" percent={Array(numberStep).fill(16.7).reduce((a, b) => a + b, 0)}>
      <AuthForm onSubmit={(e) => { e.preventDefault(); }} id="signUp">
        <HeaderWrapper>
          <Header> Create a free account </Header>
          <SubHeader>Free 50 valid emails and 100 verifications per month</SubHeader>
        </HeaderWrapper>
        <FormWrapper>
          <InputWrapper>
            <Input
              name="email"
              value={data.email}
              label="Email address"
              placeholder="Enter your work email address"
              onChange={handleChange}
              error={errors.email}
              autoFocus={!queryRelationEmail}
              onBlur={handleCheckEmail}
            />
          </InputWrapper>
          {
            numberStep > 1 ? (
              <>
                <InputInOneLineContainer>
                  <FirstNameWrapper>
                    <InputWrapper>
                      <Input
                        name="firstName"
                        value={data.firstName}
                        label="First name"
                        placeholder="Alberto"
                        error={errors.firstName}
                        onChange={handleChange}
                        onBlur={handleCheckFirstName}
                      />
                    </InputWrapper>
                  </FirstNameWrapper>
                  <InputWrapper>
                    <Input
                      name="lastName"
                      value={data.lastName}
                      label="Last name"
                      placeholder="Giovannini"
                      error={errors.firstName && errors.lastName ? ' ' : errors.lastName}
                      onChange={handleChange}
                      onBlur={handleCheckLastName}
                    />
                  </InputWrapper>
                </InputInOneLineContainer>
              </>
            ) : null
          }
          {
            numberStep > 2 ? (
              <InputWrapper>
                <Input
                  name="password"
                  value={data.password}
                  label="Password"
                  type="password"
                  placeholder="Enter password"
                  onChange={handleChange}
                  onBlur={handleCheckPassword}
                  error={errors.password}
                />
              </InputWrapper>
            ) : null
          }
        </FormWrapper>
        <Button
          htmlType="submit"
          type="primary"
          disabled={isDisabledButtonGetStarted}
          style={BtnGetStartedStyle}
          onClick={handleSubmit}
          loading={loadingSignUp}
        >
          Get started
        </Button>
        <FormDividerSignUp>or</FormDividerSignUp>
        <GoogleAuthBtn
          loaderStyle={{ maxHeight: '40px' }}
          className="google-button-sign-up"
          title="Sign up for free"
          state="sign-up"
        />
        <SubHeader style={{ marginBottom: '20px' }}>
          Already have an account?&nbsp;
          <Link to="/login" style={linkStyle}>Sign in</Link>
        </SubHeader>
      </AuthForm>
    </AuthContainer>
  );
};

export default SignUpPage;
