import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';
import { htmlToText } from 'html-to-text';
import ReactDOMServer from 'react-dom/server';
import SignatureMail from 'components/step/signatureMail';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import { getSignature } from 'helpers/sequences/signature';
import {
  SEQUENCE_DRAFT_ORDER,
  SEQUENCE_DRAFT_STATUS,
  SEQUENCE_MESSAGE_STATUS_TYPE,
  SEQUENCE_STATUS_TYPE,
  STEP_STATUS,
} from 'data/types/sequences.types';
import { connectedAccountsSelector } from './integrations';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const baseSequencesSelector = (state) => get(state, 'sequences', {});

export const sequencesSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'sequences', {}),
);

export const dashboardSequencesSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'dashboardSequences', {}),
);

export const contactSequencesSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'contactSequences', {}),
);

export const selectedLeadsSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'selectedLeads', []),
);

export const sequenceFoldersSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'folders', {}),
);

export const sequenceFoldersParamsSelector = createSelector(
  baseSequencesSelector,
  (sequences) => ({
    page: get(sequences, 'folders.page', 1),
    pageSize: get(sequences, 'folders.pageSize', 25),
  }),
);

export const sequencesParamsSelector = createSelector(
  baseSequencesSelector,
  (sequences) => ({
    page: get(sequences, 'sequences.page', 1),
    pageSize: get(sequences, 'sequences.pageSize', 25),
  }),
);

const baseSelectedSequenceSelector = (state) => get(state, 'sequences.selectedSequence', {});
export const selectedSequenceSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequences) => sequences,
);

export const selectedSequenceIdSelector = createSelector(
  selectedSequenceSelector,
  (sequence) => sequence?._id,
);

export const draftStatusSelector = createSelector(
  selectedSequenceSelector,
  (sequence) => sequence.draftStatus,
);

export const countSentMailsPerDaySelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => (typeof sequence.countSentMailsPerDay === 'number' ? sequence.countSentMailsPerDay : '∞'),
);

export const stepIdsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'steps', []).map((step) => step._id),
);

export const stepTemplatesSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'stepTemplates.data', []),
);

export const sequenceLeadsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequences) => sequences.leads,
);

export const selectAllSequenceLeadsSelector = createSelector(
  sequenceLeadsSelector,
  (leads) => leads?.selectAll,
);

export const hasSequenceLeadSelector = createSelector(
  sequenceLeadsSelector,
  (leads) => !isEmpty(get(leads, 'data[0]')),
);

export const initialRequestSequenceLeadsSelector = createSelector(
  sequenceLeadsSelector,
  (leads) => (typeof leads?.initialRequest === 'undefined' ? true : leads.initialRequest),
);

export const sequenceLeadsParamsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequences) => ({
    page: get(sequences, 'leads.page', 1),
    pageSize: get(sequences, 'leads.pageSize', 25),
  }),
);

export const sequenceLeadFiltersSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'selectedSequence.leads.filters', []),
);

export const sequenceStepsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequences) => get(sequences, 'steps', []),
);

export const invalidStepIndexSelector = createSelector(
  sequenceStepsSelector,
  (steps) => {
    const stepWithErrorIndex = steps.findIndex((step, index) => {
      const { versions } = step;
      const firstError = Object.keys(versions).find((key) => {
        if ((index === 0 && !versions[key].subject) || !versions[key].content) return true;
        const text = htmlToText(versions[key].content).replace(/\s/g, '');
        const countLetters = text.length;
        return countLetters < 5;
      });
      return !!firstError;
    });
    return stepWithErrorIndex;
  },
);

export const sequenceActiveScheduleIdSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequences) => get(sequences, 'settings.schedule') ?? get(sequences, 'settings.defaultSchedule'),
);

export const sequenceActiveStepSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => sequence.activeStep,
);

export const isSendingCurrentStepSelector = createSelector(
  sequenceActiveStepSelector,
  selectedSequenceSelector,
  (currentStep, selectedSequence) => selectedSequence.status === SEQUENCE_STATUS_TYPE.ACTIVE && (!currentStep.status || currentStep.status === STEP_STATUS.IN_PROGRESS),
);

export const isSendingAnyStepSelector = createSelector(
  selectedSequenceSelector,
  (selectedSequence) => selectedSequence.status === SEQUENCE_STATUS_TYPE.ACTIVE && selectedSequence.steps.some((step) => !step.status || step.status === STEP_STATUS.IN_PROGRESS),
);

export const hasActiveStepErrorsSelector = createSelector(
  sequenceActiveStepSelector,
  (step) => !isEmpty(step.errors),
);

export const sequenceStatsCountLeadsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.statuses.leads', 0),
);

export const sequenceStatsMainSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.statuses', {
    sent: 0,
    opened: 0,
    clicked: 0,
    replied: 0,
    bounced: 0,
    'opt-out': 0,
    invalid: 0,
  }),
);

export const isMainStatsEmptySelector = createSelector(
  sequenceStatsMainSelector,
  (stats) => Object.keys(stats).every((key) => stats[key] === 0),
);

export const sequenceLeadsStatsCountLeadsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'leads.statuses.leads', 0),
);

export const sequenceLeadsStatsMainSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'leads.statuses', {
    sent: 0,
    opened: 0,
    clicked: 0,
    replied: 0,
    bounced: 0,
    'opt-out': 0,
    invalid: 0,
  }),
);

export const sequenceStatsStepsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.steps', {}),
);

export const sequenceStatsAudienceDetailsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.audienceDetails.data', []),
);

export const filterByAudienceDetailsSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.audienceDetails.filterBy', 'position'),
);

export const filterByEngagedAudienceSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.engagedAudience.filterBy', SEQUENCE_MESSAGE_STATUS_TYPE.SENT),
);

export const sequenceStatsEngagedAudienceSelector = createSelector(
  baseSelectedSequenceSelector,
  (sequence) => get(sequence, 'stats.engagedAudience.data', []),
);

export const sequenceFilterStatusSelector = createSelector(
  sequencesSelector,
  (sequences) => get(sequences, 'filters', []).find((filter) => filter.property === 'status')?.value,
);

export const sequenceFilterAccountSelector = createSelector(
  sequencesSelector,
  (sequences) => get(sequences, 'filters', []).find((filter) => filter.property === 'account')?.value,
);

export const sequenceSearchValueSelector = createSelector(
  sequencesSelector,
  (sequences) => get(sequences, 'search.value'),
);

export const sequenceLeadsSearchValueSelector = createSelector(
  selectedSequenceSelector,
  (sequence) => get(sequence, 'search.value'),
);

const baseSelectedSequenceSettingsSelector = (state) => get(state, 'sequences.selectedSequence.settings', {});
export const sequenceAccountIdSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => get(settings, 'account'),
);

export const sequenceAccountSelector = createSelector(
  sequenceAccountIdSelector,
  connectedAccountsSelector,
  (accountId, connectedAccounts) => connectedAccounts.find((account) => account._id === accountId),
);

export const sequenceAccountEmailSelector = createSelector(
  sequenceAccountSelector,
  (account) => get(account, 'email'),
);

export const dailyLimitToEnrollSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.dailyLimitToEnroll,
);

export const countLastDaysSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.countLastDays,
);

export const repliesToMessageSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.repliesToMessage,
);

export const clickOnLinkSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.clickOnLink,
);

export const opensEmailSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.opensEmail,
);

export const trackEmailOpensSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.trackEmailOpens,
);

export const trackEmailLinkClicksSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.trackEmailLinkClicks,
);

export const isSavedSettingsSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.saved,
);

export const engagedAudienceColumnsSelector = createSelector(
  baseSelectedSequenceSettingsSelector,
  (settings) => settings.engagedAudienceColumns,
);

const baseSequenceSchedulesSelector = (state) => get(state, 'sequences.schedules', {});
export const sequenceSchedulesSelector = createSelector(
  baseSequenceSchedulesSelector,
  (schedules) => schedules.data,
);

export const lastScheduleSelector = createSelector(
  baseSequenceSchedulesSelector,
  (schedules) => schedules.lastSchedule,
);

export const savedSchedulesSelector = createSelector(
  baseSequenceSchedulesSelector,
  (schedules) => schedules.saved,
);

export const areSavedStepsSelector = createSelector(
  sequenceStepsSelector,
  (steps) => steps.every((step) => step.saved),
);

export const isSavedSequenceSelector = createSelector(
  isSavedSettingsSelector,
  areSavedStepsSelector,
  (isSavedSettings, areSavedSteps) => isSavedSettings && areSavedSteps,
);

export const isSavedSequenceFullSelector = createSelector(
  isSavedSequenceSelector,
  savedSchedulesSelector,
  (isSavedSequence, isSavedSchedule) => isSavedSequence && isSavedSchedule,
);

export const enrollmentDateFromSelector = createSelector(
  baseSelectedSequenceSelector,
  (selectedSequence) => get(selectedSequence, 'stats.enrollmentDate.from'),
);

export const enrollmentDateToSelector = createSelector(
  baseSelectedSequenceSelector,
  (selectedSequence) => get(selectedSequence, 'stats.enrollmentDate.to'),
);

export const enrollmentContactsSelector = createSelector(
  baseSelectedSequenceSelector,
  (selectedSequence) => get(selectedSequence, 'stats.enrollmentContacts', []),
);

export const enrollmentContactIdsSelector = createDeepEqualSelector(
  enrollmentContactsSelector,
  (enrollmentContacts) => enrollmentContacts.map((contact) => contact._id),
);

export const chartsSelectedStatusSelector = createSelector(
  baseSelectedSequenceSelector,
  (selectedSequence) => get(selectedSequence, 'leads.selectedStatus'),
);

export const optOutContactsSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'optOutContacts'),
);

export const initialRequestOptOutContactsSelector = createSelector(
  optOutContactsSelector,
  (optOutContacts) => optOutContacts.initialRequest,
);

export const optOutContactsSearchSelector = createSelector(
  optOutContactsSelector,
  (optOutContacts) => get(optOutContacts, 'search.value'),
);

export const initialRequestSelector = createSelector(
  sequencesSelector,
  (sequences) => get(sequences, 'initialRequest'),
);

export const initialRequestDashboardSequencesSelector = createSelector(
  dashboardSequencesSelector,
  (sequences) => get(sequences, 'initialRequest'),
);

export const templateTeammatesSelector = createSelector(
  baseSequencesSelector,
  (sequences) => sequences.templateTeammates,
);

export const isSavedCurrentSequenceSelector = createSelector(
  baseSequencesSelector,
  (sequences) => sequences.saved,
);

export const currentDraftSequenceStepNumberSelector = createSelector(
  draftStatusSelector,
  (draftStatus) => SEQUENCE_DRAFT_ORDER[draftStatus],
);

export const isAdditingLeadsSelector = createSelector(
  baseSequencesSelector,
  selectedSequenceIdSelector,
  currentDraftSequenceStepNumberSelector,
  (sequences, sequenceId, currentDraftStep) => sequences.isAdditingLeads && sequenceId && (!currentDraftStep || currentDraftStep > SEQUENCE_DRAFT_ORDER[SEQUENCE_DRAFT_STATUS.STEPS]),
);

export const customizeEmailSelector = createSelector(
  selectedSequenceSelector,
  (sequence) => sequence.customizeEmail || {},
);

export const leadReviewSelector = createSelector(
  selectedSequenceSelector,
  (sequence) => sequence.leadReview || {},
);

export const leadReviewIdSelector = createSelector(
  leadReviewSelector,
  (leadReview) => leadReview?.lead?._id,
);

export const sequenceActiveScheduleSelector = createSelector(
  sequenceActiveScheduleIdSelector,
  sequenceSchedulesSelector,
  (activeScheduleId, schedules) => schedules.find((schedule) => schedule._id === activeScheduleId),
);

export const searchLeadValueSelector = createSelector(
  selectedSequenceSelector,
  (selectedSequence) => get(selectedSequence, 'leads.search.value'),
);

export const specificLeadStepContextSelector = createSelector(
  sequenceActiveStepSelector,
  leadReviewIdSelector,
  customizeEmailSelector,
  (step, leadReviewId, customizeEmail) => {
    if (leadReviewId && !customizeEmail.wholeSequence) {
      const foundContact = step.contacts.find(({ contact }) => contact === leadReviewId);
      return foundContact;
    }
    return null;
  },
);

export const sequenceActiveVersionSelector = createSelector(
  sequenceActiveStepSelector,
  specificLeadStepContextSelector,
  (step, specificLead) => {
    if (specificLead) return 'A';
    return step.activeVersion;
  },
);

export const sequenceActiveVersionsSelector = createSelector(
  sequenceActiveStepSelector,
  specificLeadStepContextSelector,
  (step, specificLead) => {
    if (specificLead) return { A: null };
    return step.versions;
  },
);

export const currentStepContextSelector = createSelector(
  sequenceActiveStepSelector,
  specificLeadStepContextSelector,
  sequenceActiveVersionSelector,
  sequenceAccountSelector,
  (step, specificLeadContext, activeVersion, account) => {
    let version;
    if (specificLeadContext) {
      version = specificLeadContext.versions[activeVersion];
    } else {
      version = step.versions[activeVersion];
    }
    if (version) {
      let { content } = version;
      const document = new DOMParser().parseFromString(content, 'text/html');
      if (!content) {
        const emptyElement = document.createElement('p');
        emptyElement.innerHTML = '&nbsp;';
        document.body.append(emptyElement);
      }
      const signatureElement = document.querySelector('.signature-container');
      if (!signatureElement) {
        const htmlSignature = ReactDOMServer.renderToStaticMarkup(<SignatureMail account={account} />);
        document.body.innerHTML += htmlSignature;
      } else {
        let currentSignature = signatureElement.querySelector('.signature-container__content');
        if (!currentSignature) {
          const htmlSignature = ReactDOMServer.renderToStaticMarkup(<SignatureMail onlyChildren account={account} />);
          signatureElement.innerHTML = htmlSignature;
          currentSignature = signatureElement.querySelector('.signature-container__content');
        }
        const comparingSignature = getSignature(account);
        if (currentSignature?.innerHTML?.trim() !== comparingSignature) {
          currentSignature.innerHTML = comparingSignature;
        }
      }
      content = document.body.innerHTML;
      return {
        ...version,
        content,
      };
    }
    return version;
  },
);

export const folderSequenceRelationsSelector = createSelector(
  baseSequencesSelector,
  (sequences) => get(sequences, 'foldersRelation', []),
);
