export const IMPORT_TYPE = Object.freeze({
  LIST_OF_ENRICHMENT: 'list_of_enrichment',
  EXISTING_LIST: 'existing_list',
  IGNORE_LIST: 'ignore_list',
  OPT_OUT_LIST: 'opt_out_list',
});

export const IMPORT_DISPATCH_TYPE = Object.freeze({
  IMPORT_FILE_PREPROCESS: 'IMPORT_FILE_PREPROCESS',
  IMPORT_FILE_SEARCH_FILTER_PREPROCESS: 'IMPORT_FILE_SEARCH_FILTER_PREPROCESS',
});
