import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import { TOUR } from 'data/types/tours.types';
import { useCallback, useRef } from 'react';

import { SOURCE_TYPE } from 'data/types/source.types';
import { INSIGHTS_TYPE } from 'data/types/insights.types';
import { getInsightContactsCount } from 'data/actions/contacts';
import { getSavedInsightCompanies } from 'data/actions/companies';
import { searchInsights, setSearchError } from 'data/actions/search';
import { INSIGHTS_REQUEST_TYPE } from 'data/types/insights.request.type';

const useInsightSearch = () => {
  const dispatch = useDispatch();

  const previousFiltersRef = useRef();

  const searchInsightsAction = useCallback((source, props) => new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams(window.location.search);
    const demo = TOUR.SEARCH_LEADS_DATABASE === queryParams.get('tour');
    if (demo) props.pageSize = 5;
    if (!demo && props.pageSize === 5) props.pageSize = 25;
    dispatch(searchInsights(
      source,
      {
        ...props,
        onSuccess: (data) => resolve(data),
        onFailed: (err) => reject(err),
      },
      demo,
    ));
  }), [dispatch]);

  return useCallback(async (props, sourceType) => {
    let totalItems = 0;
    try {
      const insightsData = await searchInsightsAction(sourceType, props);
      totalItems = get(insightsData, 'meta.totalItems', 0);
      if (sourceType !== SOURCE_TYPE.CONTACT) {
        dispatch(getSavedInsightCompanies({ data: get(insightsData, 'data', []) }));
      }
      const { filters, selectedTab } = props;
      if (filters && selectedTab && sourceType === SOURCE_TYPE.CONTACT) {
        const needToCount = !isEqual(filters, previousFiltersRef.current);
        if (needToCount) {
          dispatch(getInsightContactsCount({
            totalItems,
            filters,
            requestType: selectedTab === INSIGHTS_TYPE.INSIGHTS ? INSIGHTS_REQUEST_TYPE.INCLUDED : INSIGHTS_REQUEST_TYPE.ALL,
            selectedTab,
          }));
          previousFiltersRef.current = cloneDeep(filters);
        }
      }
      // eslint-disable-next-line
    } catch (error) {
      dispatch(setSearchError(error));
    }
    return totalItems;
  }, [dispatch, searchInsightsAction]);
};

export default useInsightSearch;
