import get from 'lodash/get';
import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAccount,
  updateAccount,
  updateSignUpShowLastStep,
  signOut,
  getUserInfo,
} from 'data/actions/user';
import { toggleModal } from 'data/actions/modals';
import { Label } from 'components/commonStyles';
import Suspense from 'components/common/suspense';
import WhiteLoading from 'components/whiteLoading';
import {
  PhoneInput,
  Button,
} from 'components/common';
import { validatePhone } from 'helpers/validate';
import {
  accountSelector,
  authTokenSelector,
  userInfoSelector,
  userEmailSelector,
} from 'data/selectors/user';
import AuthContainer from '../AuthContainer';
import {
  formBtnStyle,
  Header,
  AuthForm,
  SubHeader,
} from '../styles';
import {
  ButtonContainer,
} from './styles';
import { HeaderWrapper } from '../signUp/styles';

const UnconfirmedPhone = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(userInfoSelector);
  const email = useSelector(userEmailSelector);
  const tokenAuth = useSelector(authTokenSelector);
  const { phoneVerified } = useSelector(accountSelector);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoadingVerifyPhone, setIsLoadingVerifyPhone] = useState(false);
  const [userPhone, setUserPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('us');
  const [isSetUserInfo, setIsSetUserInfo] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    if (email && !isSetUserInfo) dispatch(getUserInfo({ data: { email }, onSuccess: () => setIsSetUserInfo(true) }));
  }, [dispatch, email, isSetUserInfo]);

  useEffect(() => {
    dispatch(getAccount(100, () => {
      setLoading(false);
    }, () => {
      setLoading(false);
    }));
  }, [dispatch]);

  useEffect(() => {
    if (tokenAuth && phoneVerified) {
      history.push('/');
    }
  }, [tokenAuth, history, phoneVerified]);

  useEffect(() => {
    const country = get(userInfo, 'countryCode', '');

    if (country) {
      setPhoneCountry(country.toLowerCase());
    }
  }, [userInfo]);

  const onSuccessVerifyPhone = (verificationToken) => {
    setIsLoadingVerifyPhone(false);
    setToken(verificationToken || true);
    dispatch(updateAccount(
      [{ phone: userPhone.startsWith('+') ? userPhone : `+${userPhone}`, token }],
      () => dispatch(updateSignUpShowLastStep(true)),
      (err) => setError(err.response?.data?.message || err.message),
      false,
    ));
  };

  const handleContinue = () => {
    if (validatePhone(null, { phone: userPhone, countryCode: phoneCountry })) {
      setIsLoadingVerifyPhone(true);
      dispatch(toggleModal(
        'verify_phone',
        true,
        {
          phone: userPhone,
          onSuccess: (verificationToken) => onSuccessVerifyPhone(verificationToken),
          onFailed: (_error) => {
            setIsLoadingVerifyPhone(false);
            setError(_error);
          },
        },
      ));
    } else {
      setError('Invalid phone number');
    }
  };

  const handleSignOut = () => dispatch(signOut(() => {
    history.push('/sign-up');
  }));

  return (
    <AuthContainer percent={100}>
      <AuthForm>
        <Suspense loading={loading} fallback={<WhiteLoading height="457px" width="100%" />}>
          <HeaderWrapper>
            <Header> Verify your account </Header>
            <SubHeader>Complete sign-up by verifying your phone number</SubHeader>
          </HeaderWrapper>
          <Label> Phone number </Label>
          { !loading ? (
            <div style={{ height: '58px' }}>
              <PhoneInput
                onChange={(value, data) => {
                  setError();
                  setUserPhone(value);
                  setPhoneCountry(data.countryCode);
                }}
                inputProps={{
                  name: 'phone',
                }}
                phoneVerification={token}
                country={phoneCountry}
                value={userPhone}
                isValid={!!userPhone}
                error={error}
                styleWrapper={{ marginBottom: 5 }}
              />
            </div>
          ) : null }
          <ButtonContainer>
            <Button
              disabled={error}
              type="primary"
              style={{ ...formBtnStyle }}
              onClick={handleContinue}
              loading={isLoadingVerifyPhone}
            >
              Continue
            </Button>
          </ButtonContainer>
        </Suspense>
        <SubHeader style={{ marginTop: '18px' }}>
          Wrong account?&nbsp;
          <Link onClick={handleSignOut} to="/login">Log out</Link>
        </SubHeader>
      </AuthForm>
    </AuthContainer>
  );
};

export default UnconfirmedPhone;
