import get from 'lodash/get';
import * as encodingDetector from 'detect-file-encoding-and-language';

import { SOURCE_TYPE } from 'data/types/source.types';
import { AWS_FILE_TYPES } from 'data/types/aws.types';
import { IMPORT_DISPATCH_TYPE, IMPORT_TYPE } from 'data/types/import.types';

export const importFilePreSigned = ({ file: { file, dispatchType = IMPORT_DISPATCH_TYPE.IMPORT_FILE_PREPROCESS, filterProperty }, onError = () => {}, onSuccess = () => {} }) => async (dispatch) => {
  const detectedEncoding = await encodingDetector(file);
  dispatch({
    type: 'IMPORT_FILE_PRE_SIGNED',
    payload: {
      endpoint: 'aws/pre-signed',
      method: 'POST',
      body: {
        contentType: 'text/csv',
        preSignedType: AWS_FILE_TYPES.FILE,
        encoding: detectedEncoding?.encoding,
      },
    },
    onSuccess: (data) => {
      const { fields, url } = data;
      const form = new FormData();
      Object.keys(fields).forEach((key) => {
        form.append(key, fields[key]);
      });
      form.append('file', file);

      dispatch({
        type: 'UPLOAD_FILE_TO_S3',
        payload: {
          custom: true,
          endpoint: url,
          method: 'POST',
          body: form,
        },
        onSuccess: () => {
          dispatch({
            type: dispatchType,
            payload: {
              endpoint: 'aws/preprocess',
              method: 'POST',
              body: {
                key: fields.Key,
              },
            },
            onFailed: (error) => onError(error),
            onSuccess: (res) => onSuccess(res),
            fileUrl: url,
            originalName: file.name,
            key: fields.Key,
            filterProperty,
          });
        },
        onFailed: (error) => onError(error),
      });
    },
    onFailed: (error) => onError(error),
  });
};

export const clearImport = () => (dispatch) => {
  dispatch({
    type: 'CLEAN_IMPORT',
    data: {},
  });
};

export const addPropertyMatching = (source, getProspect) => (dispatch) => {
  dispatch({
    type: 'ADD_PROPERTY_MATCHING',
    data: {
      source, getProspect,
    },
  });
};

export const addAutoMatchedProperties = (data) => (dispatch, getStore) => {
  let dataToAutoMatch = data;
  const store = getStore();
  const matchedProperties = get(store, 'import.matchedProperties', []);

  if (matchedProperties.length) {
    dataToAutoMatch = matchedProperties;
  }

  dispatch({
    type: 'ADD_AUTO_MATCHED_PROPERTIES',
    data: dataToAutoMatch,
  });
};

export const removePropertyMatching = (source) => (dispatch) => {
  dispatch({
    type: 'REMOVE_PROPERTY_MATCHING',
    data: {
      source,
    },
  });
};

export const updatePropertyMatching = (matched) => (dispatch) => {
  dispatch({
    type: 'UPDATE_PROPERTY_MATCHING',
    data: {
      matched,
    },
  });
};

export const updateImportSettings = (field, value) => (dispatch) => {
  if (Array.isArray(field)) {
    return dispatch({
      type: 'UPDATE_IMPORT_SETTINGS',
      data: field,
    });
  }

  return dispatch({
    type: 'UPDATE_IMPORT_SETTINGS',
    data: {
      field,
      value,
    },
  });
};

export const updateSequenceImportSettings = (sequenceId) => (dispatch) => {
  dispatch(updateImportSettings([
    {
      field: 'source',
      value: SOURCE_TYPE.CONTACT,
    },
    {
      field: 'type',
      value: IMPORT_TYPE.EXISTING_LIST,
    },
    {
      field: 'sequenceId',
      value: sequenceId,
    },
    {
      field: 'step',
      value: 2,
    },
  ]));
};

export const setDefaultImportSettings = (step) => (dispatch) => {
  dispatch({
    type: 'SET_DEFAULT_IMPORT_SETTING',
    data: step,
  });
};

export const createImport = (data, onSuccess = () => {}, onError = () => {}) => (dispatch) => {
  dispatch({
    type: 'CREATE_IMPORT',
    payload: {
      endpoint: 'api/v1/import',
      method: 'POST',
      body: data,
    },
    onSuccess,
    onError,
  });
};

export const getAllImports = ({
  pageSize, page,
}) => (dispatch) => {
  const params = {
    pageSize: pageSize || 10,
    pageNumber: page || 1,
  };

  dispatch({
    type: 'GET_ALL_IMPORTS',
    payload: {
      endpoint: 'api/v1/import',
      method: 'GET',
      params,
    },
  });
};

export const getSingleImport = (id) => (dispatch) => {
  dispatch({
    type: 'GET_SINGLE_IMPORT',
    payload: {
      endpoint: `api/v1/import/${id}`,
      method: 'GET',
    },
  });
};

export const findSuggestedImports = ({
  name = '',
  limit = 10,
  components = [],
  onSuccess = () => {},
}) => (dispatch) => {
  dispatch({
    type: 'GET_SUGGESTED_IMPORTS',
    payload: {
      endpoint: 'api/v1/import/search',
      method: 'GET',
      params: {
        name,
        limit,
      },
    },
    components,
    onSuccess,
  });
};

export const getImport = (importId, onSuccess = () => {}, onFailed = () => {}) => (dispatch, getStore) => {
  const store = getStore();
  const currentImport = get(store, 'import.imports.data', []).find((importItem) => importItem._id === importId);

  if (currentImport) {
    dispatch({
      type: 'GET_IMPORT',
      data: currentImport,
    });
    onSuccess(currentImport);
  } else {
    dispatch({
      type: 'GET_IMPORT',
      payload: {
        endpoint: `api/v1/import/${importId}`,
        method: 'GET',
        delayLoad: 0,
      },
      onSuccess,
      onFailed,
    });
  }
};

export const s3SelectFromFileForSearch = ({
  preprocessedData, onSuccess = () => {}, onFailed = () => {},
}) => (dispatch) => {
  const {
    fileUrl, matchedHeader, headers, delimiter,
  } = preprocessedData;
  let matchedHeaderIndex = headers.findIndex((header) => header === matchedHeader);

  if (matchedHeaderIndex > -1) {
    matchedHeaderIndex += 1;
  }

  dispatch({
    type: 'IMPORT_SEARCH_SELECT',
    payload: {
      endpoint: 'aws/search/select',
      method: 'POST',
      body: {
        delimiter,
        matchedHeader,
        matchedIndex: matchedHeaderIndex,
        url: fileUrl,
      },
    },
    onSuccess,
    onFailed,
  });
};
